import type { GetStaticPaths, GetStaticProps, NextPage } from "next";
import React, { useMemo } from "react";
import { Organization, WithContext } from "schema-dts";
import { BrandSlug, siteConfig } from "../../portalstack.config";
import { useBrandComponents } from "../../brandComponents";
import {
	BrandWrapper,
	SharedBrandWrapperProps,
} from "../../components/BrandWrapper";
import { articleDataToTeaserData } from "../../components/teasers/utils";
import { useEnrichedMetaTags } from "../../hooks/useEnrichedMetaTags";
import { CMSClientType } from "../../lib/cms";
import { GetStaticPropsService } from "../../lib/getStaticProps/getStaticProps.index.service";
import { truthy } from "../../utils/misc";
import {
	AddParameters,
	Article,
	CrossPromoData,
	DisallowUndefined,
} from "../../utils/types";

export type Props = SharedBrandWrapperProps & {
	structuredOrganizationContent: WithContext<Organization>;
	articles: Array<Article>;
	crossPromoData: CrossPromoData | null;
};

const BrandHome: NextPage<Props> = ({
	articles,
	brandSlug,
	brandSettings,
	crossPromoData,
	...rest
}) => {
	const { HomePage } = useBrandComponents(brandSlug);
	const teasers = useMemo(() => {
		return articles.map(articleDataToTeaserData).filter(truthy);
	}, [articles]);

	const metaTags = useEnrichedMetaTags(brandSettings.seo, brandSlug);

	return (
		<BrandWrapper
			brandSettings={brandSettings}
			brandSlug={brandSlug}
			metaTags={metaTags}
			{...rest}
		>
			<HomePage
				crossPromoData={crossPromoData ?? undefined}
				teasers={teasers}
				layout={brandSettings.layout}
			/>
		</BrandWrapper>
	);
};

export const getStaticProps: AddParameters<
	GetStaticProps<DisallowUndefined<Props>, { brand: BrandSlug }>,
	[client?: CMSClientType]
> = async ({ preview, ...ctx }, CMSClient) => {
	const propsService = new GetStaticPropsService(
		{
			preview,
			...ctx,
		},
		CMSClient,
	);

	await propsService.init(true);

	return propsService.get();
};

export const getStaticPaths: GetStaticPaths = async () => {
	const paths = siteConfig.brands.map(({ slug: brand }) => ({
		params: { brand },
	}));

	return {
		paths,
		fallback: "blocking",
	};
};

export default BrandHome;
