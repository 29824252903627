import React from "react";
import type { ResponsiveImageType } from "react-datocms";
import { BrandSlug } from "../../portalstack.config";
import type { ArticleTrackingType } from "../../lib/gtm/dataLayer";
import { normalizeResponsiveImageData } from "../../lib/normalizeGalleryData";
import type { CanonicalArticleUrlParams } from "../../utils/getStaticPropsUtils";
import { getNormalizedDates, NormalizedDates, truthy } from "../../utils/misc";
import { orderCategories } from "../../utils/orderCategories";
import { Article } from "../../utils/types";

export type TeaserData = {
	highlighted?: boolean;
	title: string;
	teaserText?: string;
	category?: string;
	image: {
		hero?: ResponsiveImageType;
		regular?: ResponsiveImageType;
		outlined?: ResponsiveImageType;
	};
	company: {
		wkn?: string;
		name?: string;
	};
	urlParams: CanonicalArticleUrlParams;
	trackingType?: ArticleTrackingType;
	publicationDate?: string;
	brandSlug?: BrandSlug;
	brandLabel?: string;
	dates?: NormalizedDates;
};

export type TeaserVariant =
	| "regular"
	| "overview"
	| "list"
	| "hero"
	| "text-only"
	| "single-eyecatcher"
	| "outlined";

export const TeaserVariantContext =
	React.createContext<TeaserVariant>("regular");

type ArticleType = Omit<Article, "__typename">;

export const articleDataToTeaserData = (
	article?: ArticleType,
): TeaserData | undefined => {
	if (!article) {
		return undefined;
	}

	const {
		categories,
		company,
		highlighted,
		image,
		imageHero,
		imageOutlined,
		slug,
		subline,
		teaserText,
		title,
		brand,
		_draftFirstPublishedAt,
		_draftLastUpdatedAt,
		_firstPublishedAt,
		_lastUpdatedAt,
	} = article;

	if (!slug || !title) {
		return undefined;
	}

	const dates = getNormalizedDates({
		_draftFirstPublishedAt,
		_draftLastUpdatedAt,
		_firstPublishedAt,
		_lastUpdatedAt,
	});

	const canonicalUrlParams: CanonicalArticleUrlParams = {
		_firstPublishedAt,
		categories: categories.map((c) => c.slug).filter(truthy),
		company: {
			isin: company?.isin ?? undefined,
			slug: company?.slug ?? undefined,
		},
		slug,
	};

	const category = orderCategories(categories).pop()?.name ?? undefined;

	return {
		title,
		highlighted,
		category,
		dates,
		trackingType: "article_teaser_click",
		teaserText: teaserText ?? subline ?? undefined,
		company: {
			wkn: company?.wkn ?? undefined,
			name: company?.name ?? undefined,
		},
		image: {
			outlined: normalizeResponsiveImageData(
				imageOutlined?.responsiveImage,
			),
			hero: normalizeResponsiveImageData(imageHero?.responsiveImage),
			regular: normalizeResponsiveImageData(image?.responsiveImage),
		},
		urlParams: canonicalUrlParams,
		publicationDate: _firstPublishedAt,
		brandSlug: (brand?.slug as BrandSlug | null | undefined) ?? undefined,
		brandLabel: brand?.label ?? undefined,
	};
};

export const findImageData = (
	data: TeaserData["image"],
): ResponsiveImageType | undefined => {
	return [data.hero, data.outlined, data.regular].find((a) =>
		Boolean(a?.src),
	);
};
